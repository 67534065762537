//
// useGetProjectDatabases.ts
//

import { useFetchData } from "@custom-hooks/useFetchData";
import { Databases } from "@data-types/databases-types";
import { FetchHookResult } from "@data-types/generic-hook-type";

/**
 * Custom hook to fetch and manage database information for a specified project ID.
 *
 * @param {string} projectId - The unique identifier of the project for which to fetch database information.
 * @param {number} [refreshInterval] - Interval (in milliseconds) for refreshing database data.
 * @param {number} [errorRetryCount] - Max error retry count.
 *
 * @returns {FetchHookResult<Databases>} The result object with the list of databases
 *   (or `undefined` if no databases are available), UI state indicators, and a function to manage fetched data.
 */
export function useGetProjectDatabases(
  projectId: string,
  refreshInterval?: number,
  errorRetryCount?: number
): FetchHookResult<Databases> {
  const { data, ...fetchDetails } = useFetchData<Databases>(
    projectId !== "",
    `/api/projects/${projectId}/databases`,
    "useGetProjectDatabases",
    "backend",
    { refreshInterval, revalidateOnFocus: false, errorRetryCount }
  );

  return {
    data: data?.sort((a, b) => a.name.localeCompare(b.name)),
    ...fetchDetails,
  };
}
