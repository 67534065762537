//react
import React from "react";
//css
import styled from "@emotion/styled";
//sqlitecloud lib
import {
  disableSendMail,
  fetchApiRoute,
  getExecutionAmbient,
} from "@lib/client-side";
//sqlitecloud lib
import Code from "@components/Code";
//style
const Wrapper = styled.div`
  margin: auto;
  max-width: 22rem;
  border: solid 1px gray;
  border-radius: 0.5rem;
  padding: 0.5rem;
  margin: 2rem 0.5rem;
`;

const copyToClipboard = (error) => {
  try {
    navigator.clipboard.writeText(error);
  } catch (copyError) {
    console.log(copyError);
  }
};

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    // Define a state variable to track whether is an error or not
    this.state = { hasError: false };
    this.state = { error: null };
    this.state = { errorInfo: null };
  }
  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI
    return { hasError: true };
  }
  async componentDidCatch(error, errorInfo) {
    this.setState({ error: error });
    this.setState({ errorInfo: errorInfo });
    //get the domain from where the mail is sent
    let ambient = getExecutionAmbient();
    //based on actual page appends other info to the html body
    const additionalInfo = {};
    if (window.location.pathname.includes("console")) {
      additionalInfo.lastSql = window.lastSql;
    }
    //build message
    const subject = `Error Boundary ❌ | ${ambient} | SQLite Cloud Dashboard`;
    const html = `
<h2>Error</h2>
<ul>
	<li>
		<p>Description: ${error.toString()}</p>
	</li>
	<li>
		<p>Url: ${window.location.href}<p>
	</li>
	<li>
		<p>Info: ${JSON.stringify(errorInfo, null, 2)}</p>
	</li>
	<li>
		<p>Page specific debug data: ${JSON.stringify(additionalInfo, null, 2)}</p>
	</li>
</ul>
`;
    //build body
    const body = {
      subject: subject,
      html: html,
      type: "logs",
    };
    //try send message
    const disable = disableSendMail();
    if (!disable) {
      try {
        //send error mail only if it is an error not handled by the backend
        const opt = {
          method: "POST",
          endpoint: "/api/sendMail",
          endpointCallLocation: "ErrorBoundary",
          body: body,
        };
        const sendResult = await fetchApiRoute(opt);
      } catch (error) {
        console.log(error);
      }
    }
  }
  render() {
    // Check if the error is thrown
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Wrapper>
          {this.state.error && this.state.errorInfo && (
            <>
              <div className="">
                <p>We're sorry — something's gone wrong.</p>
                <p>Our team has been notified</p>
              </div>
              <div>Error:</div>
              <ul>
                <li>{this.state.error.toString()}</li>
              </ul>
              <Code>{JSON.stringify(this.state.errorInfo, null, 2)}</Code>
              <button
                onClick={() =>
                  copyToClipboard(JSON.stringify(this.state.errorInfo, null, 2))
                }
              >
                Copy error
              </button>
            </>
          )}
        </Wrapper>
      );
    }
    // Return children components in case of no error
    return this.props.children;
  }
}

export default ErrorBoundary;
