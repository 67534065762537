//react components
import { useContext, useRef } from "react";
//sqlitecloud context
import { StateContext } from "@context/StateContext";

const useSetSnackNotification = () => {
  //get snackNotifications from context
  const { snackNotifications, setSnackNotifications } =
    useContext(StateContext);
  const snackNotificationsRef = useRef(null);
  snackNotificationsRef.current = snackNotifications;
  const createSnackNotification = (notification) => {
    const renderCondition = notification;
    if (renderCondition) {
      let newSnackNotifications;
      if (
        snackNotificationsRef.current &&
        snackNotificationsRef.current.length > 0
      ) {
        newSnackNotifications = snackNotificationsRef.current.slice(0);
      } else {
        newSnackNotifications = [];
      }
      newSnackNotifications.unshift(notification);
      snackNotificationsRef.current = newSnackNotifications;
      setSnackNotifications(snackNotificationsRef.current);
    }
  };
  return { createSnackNotification };
};

export { useSetSnackNotification };
