//
// nodeIsWorking.ts
//

import { Node } from "@data-types/nodes-types";

/**
 * Checks if a node is working based on its match_leader property.
 *
 * @param { Node | undefined } node - The node object to check.
 *
 * @returns {boolean} Returns `true` if the node is working (i.e., match_leader > 0), otherwise `false`.
 */
export function nodeIsWorking(node?: Node): boolean {
  // Check if node exists and has a match_leader value greater than 0
  return node && node.match_leader && node.match_leader > 0 ? true : false;
}
