//
// getNodeUUID.ts
//

import { Node } from "@data-types/nodes-types";

/**
 * Extracts the UUID from a node's address.
 *
 * @param { Node | undefined } node - The node object containing the address.
 *
 * @returns {string} The extracted UUID or "unknown" if the address is unavailable.
 */
export function getNodeUUID(node?: Node): string {
  if (node?.address) {
    const address = node.address;
    const firstPointIndex = address.indexOf(".");

    // Extract the substring from the start of the address to the first period
    const uuid = address.substring(0, firstPointIndex);
    return uuid;
  } else {
    return "unknown";
  }
}
