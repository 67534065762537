//
// getNodeLocation.ts
//

import { Node } from "@data-types/nodes-types";

/**
 * Extracts the location from a node's region string, assuming the location is enclosed in parentheses.
 *
 * @param { Node | undefined } node - The node object containing the region information.
 *
 * @returns {string} The extracted location or "unknown" if not found.
 */
export function getNodeLocation(node?: Node): string {
  if (node?.region) {
    const region = node.region;
    const regex = /\((.*?)\)/;

    // Match the location within parentheses in the region string
    const matches = region.match(regex);
    if (matches) {
      const location = matches[1];
      return location;
    } else {
      return "unknown";
    }
  } else {
    return "unknown";
  }
}
