//
// app.tsx
//

import { StateProvider } from "@context/StateContext";
import { ThemeProvider } from "@context/ThemeContext";
import { useGetUser } from "@custom-hooks/useApi";
import { useSetError } from "@custom-hooks/useSetError";
import useTheme from "@custom-hooks/useTheme";
import { CacheProvider, EmotionCache } from "@emotion/react";
import ErrorBoundary from "@generic-components/ErrorBoundary";
import { analyzeError } from "@lib/client-side";
import createEmotionCache from "@lib/createEmotionCache";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import createTheme from "@theme";
import "@vendor/jvectormap.css";
import "@vendor/perfect-scrollbar.css";
import { NextPage } from "next";
import { AppProps } from "next/app";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import { useEffect, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { SWRConfig } from "swr";
import "./style.css";

// Define a custom type for NextPage to include getLayout
type NextPageWithLayout = NextPage & {
  getLayout?: (page: React.ReactNode) => React.ReactNode;
};

// Extend AppProps to use the custom NextPageWithLayout type
type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

// Client-side Emotion cache setup
const clientSideEmotionCache = createEmotionCache();

// PostHog initialization (only client-side for Next.js SSR)
if (typeof window !== "undefined") {
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY || "", {
    api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST || "https://app.posthog.com",
    loaded: (posthog) => {
      if (process.env.NODE_ENV === "development") posthog.debug();
    },
    autocapture: false,
    capture_pageview: false,
    before_send: (event) => {
      if (process.env.NODE_ENV === "development") {
        console.log(`
          posthog [debug]: Event captured! (Won't be sent in the development environment)
          -----------------------------------
          Event: ${event?.event}
          Properties: ${event?.properties ? JSON.stringify(event.properties, null, 2) : "No properties"}
          -----------------------------------
        `);

        return null;
      }
      return event;
    },
  });
}

/**
 * Main App component to initialize application providers and configurations.
 * Sets up Emotion cache, PostHog, theme, error handling, and other necessary contexts.
 *
 * @param Component - The main component to render.
 * @param emotionCache - Optional Emotion cache; defaults to client-side cache.
 * @param pageProps - Props to be passed to the main component.
 * @returns {JSX.Element} The rendered application.
 */
function App({
  Component,
  emotionCache = clientSideEmotionCache,
  pageProps,
}: AppPropsWithLayout & { emotionCache?: EmotionCache }) {
  // Get theme and set layout for the component
  const { theme } = useTheme();
  const getLayout = Component.getLayout ?? ((page: React.ReactNode) => page);

  // Error handling state and functions
  const [error, setError] = useState<Error | null>(null);
  useSetError(error);

  // Retrieve user data for PostHog analytics
  const { user } = useGetUser();

  // Identify user in PostHog
  useEffect(() => {
    if (user?.email) {
      posthog.identify(user.email);
    }
  }, [user]);

  return (
    <PostHogProvider client={posthog}>
      <CacheProvider value={emotionCache}>
        <HelmetProvider>
          <Helmet titleTemplate="%s" defaultTitle="SQLite Cloud Dashboard" />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <MuiThemeProvider theme={createTheme(theme)}>
              <ErrorBoundary>
                <SWRConfig
                  value={{
                    onError: (error, key) => {
                      const result = analyzeError(error);
                      if (result.reportError) {
                        setError(error);
                      }
                    },
                    onErrorRetry: (
                      error,
                      key,
                      config,
                      revalidate,
                      { retryCount }
                    ) => {
                      const result = analyzeError(error);
                      // Retry up to 10 times, with 3 seconds between retries
                      if (!result.retry || retryCount >= 10) return;
                      setTimeout(() => revalidate({ retryCount }), 3000);
                    },
                  }}
                >
                  {getLayout(<Component {...pageProps} />)}
                </SWRConfig>
              </ErrorBoundary>
            </MuiThemeProvider>
          </LocalizationProvider>
        </HelmetProvider>
      </CacheProvider>
    </PostHogProvider>
  );
}

/**
 * Higher-order component to provide theme and state contexts to the app.
 *
 * @param Component - The main App component to wrap.
 * @returns {JSX.Element} The App component wrapped with providers.
 */
const withThemeProvider = (Component: typeof App) => {
  const AppWithThemeProvider = (props: AppProps) => (
    <ThemeProvider>
      <StateProvider>
        <Component {...props} />
      </StateProvider>
    </ThemeProvider>
  );

  AppWithThemeProvider.displayName = "AppWithThemeProvider";
  return AppWithThemeProvider;
};

export default withThemeProvider(App);
