//
// styleNodeBasedOnType.ts 
//

import { Node, NodeType } from "@data-types/nodes-types";

/**
 * Determines the style attributes for a node based on its type and working status.
 *
 * @param { Node } node - The node object with type and match_leader properties.
 * @param {any} theme - The theme object containing color palette information.
 *
 * @returns {{
 *   color: string;
 *   type: string;
 * }} An object representing the color and tooltip message for the node type.
 */
export function styleNodeBasedOnType(
  node: Node,
  theme: any
): { color: string; type: string } {
  let nodeType = node.type;
  const nodeMatchLeader = node.match_leader;
  let nodeIndicator: { color: string; type: string };

  if (!nodeMatchLeader) {
    nodeType = "not working";
  } else {
    nodeType = nodeType
      ? (nodeType.toLowerCase() as NodeType)
      : ("undefined" as NodeType);
  }

  switch (nodeType) {
    case "not working":
      nodeIndicator = {
        color: theme.palette.error.light,
        type: "Node isn't working.",
      };
      break;
    case "leader":
      nodeIndicator = {
        color: theme.palette.success.main,
        type: "Node is leader.",
      };
      break;
    case "follower":
      nodeIndicator = {
        color: theme.palette.secondary.main,
        type: "Node is a follower.",
      };
      break;
    case "learner":
      nodeIndicator = {
        color: theme.palette.info.light,
        type: "Node is a learner.",
      };
      break;
    case "undefined":
      nodeIndicator = {
        color: theme.palette.info.light,
        type: "Node type not defined.",
      };
      break;
    default:
      nodeIndicator = {
        color: theme.palette.error.light,
        type: "Node type not defined.",
      };
  }

  return nodeIndicator;
}
