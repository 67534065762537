const breakpoints = {
  values: {
    xs: 0,
    modal: 500,
    sm: 600,
    md: 960,
    semi: 1128,
    lg: 1280,
    xl: 1440,
  },
};

export default breakpoints;
