//
// databaseNameExist.ts
//

import { Database, Databases } from "@data-types/databases-types";

/**
 * Checks if a database name exists in the list of databases.
 *
 * @param {Database["name"]} databaseName - The name of the database to search for.
 * @param {Databases} databasesList - The list of databases, each with a `name` property.
 *
 * @returns {boolean} Returns `true` if the database name exists, otherwise `false`.
 */
export function databaseNameExist(
  databaseName: Database["name"],
  databasesList: Databases
): boolean {
  if (databasesList) {
    return databasesList.some((database) => database.name === databaseName);
  }
  return false;
}
