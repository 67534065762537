"use client";

//react
import { createContext, useEffect, useState } from "react";
//next.js
// import { useRouter } from 'next/router';
//sqlitecloud custom hooks
import { useGetUser } from "@custom-hooks/useApi";

//init context
const StateContext = createContext();

function StateProvider({ children }) {
  //browser tab unique id
  const [tabId, setTabId] = useState(null);
  //user info related states
  const { user: userInfo } = useGetUser();
  const [simulateGenericUser, setSimulateGenericUser] = useState(true);
  //restore from local storage simulateGenericUser state
  useEffect(() => {
    if (userInfo) {
      const companyId = JSON.parse(userInfo?.organizations)[0].organization_id;
      var retrievedData = localStorage.getItem(`${companyId}-${userInfo.id}`);
      try {
        var simulateGenericUser = JSON.parse(retrievedData);
        if (simulateGenericUser === false || simulateGenericUser === true) {
          setSimulateGenericUser(simulateGenericUser);
        } else {
          setSimulateGenericUser(true);
        }
      } catch (error) {
        setSimulateGenericUser(true);
      }
    } else {
      setSimulateGenericUser(true);
    }
  }, [userInfo]);
  //handle snack notifications
  const [snackNotifications, setSnackNotifications] = useState([]);
  //return
  return (
    <StateContext.Provider
      value={{
        tabId,
        setTabId,
        simulateGenericUser,
        setSimulateGenericUser,
        snackNotifications,
        setSnackNotifications,
      }}
    >
      {children}
    </StateContext.Provider>
  );
}

export { StateContext, StateProvider };
