//
// useCustomRouter.ts - Cross-Compatible hook that works both in App router and Pages router
//

//next.js
import {
  useRouter as useAppRouter,
  useParams,
  usePathname,
  useSearchParams,
} from "next/navigation";
import { useRouter as usePagesRouter } from "next/router";
//sqlitecloud hooks
import { useIsAppRouter } from "@custom-hooks/useIsAppRouter";

function useCustomRouter() {
  // Detect the environment (App Router vs Pages Router)
  const isAppRouter = useIsAppRouter();
  // console.debug(`Is app router: ${isAppRouter}`);
  // App Router (next/navigation)

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const pathname = isAppRouter ? usePathname() : null;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const searchParams = isAppRouter ? useSearchParams() : null;

  const asPath = isAppRouter ? pathname : null; // App Router doesn't have `asPath`, so fallback to pathname
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const params = isAppRouter ? useParams() : null;

  // Parse the query from URLSearchParams in the App Router
  const searchParamsParsed =
    isAppRouter && searchParams
      ? Object.fromEntries(searchParams.entries())
      : null;
  const query = { ...searchParamsParsed, ...params };

  // Pages Router (next/router)
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const pagesRouter = !isAppRouter ? usePagesRouter() : null;
  const pagePathname = pagesRouter ? pagesRouter.pathname : null;
  const pageAsPath = pagesRouter ? pagesRouter.asPath : null;
  const pageQuery = pagesRouter ? pagesRouter.query : null;
  // Return the relevant values for both routers
  return {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    router: isAppRouter ? useAppRouter() : pagesRouter,
    pathname: isAppRouter ? pathname : pagePathname,
    asPath: isAppRouter ? asPath : pageAsPath,
    query: isAppRouter ? query : pageQuery,
  };
}

/* TRIED TO REMOVE CONDIONALLY CALL BUT NOT WORKING
function useCustomRouter() {
  // Detect the environment (App Router vs Pages Router)
  const isAppRouter = useIsAppRouter();
  // console.debug(`Is app router: ${isAppRouter}`);
  // App Router (next/navigation)
  const getUseAppRouter = useAppRouter();
  const getUserPathName = usePathname();
  const pathname = isAppRouter ? getUserPathName : null;
  const getUseSearchParams = useSearchParams();
  const searchParams = isAppRouter ? getUseSearchParams : null;
  const asPath = isAppRouter ? pathname : null; // App Router doesn't have `asPath`, so fallback to pathname
  const getUseParams = useParams();
  const params = isAppRouter ? getUseParams : null;

  // Parse the query from URLSearchParams in the App Router
  const searchParamsParsed =
    isAppRouter && searchParams
      ? Object.fromEntries(searchParams.entries())
      : null;
  const query = { ...searchParamsParsed, ...params };

  // Pages Router (next/router)
  const getUsePagesRouter = usePagesRouter();
  const pagesRouter = !isAppRouter ? getUsePagesRouter : null;
  const pagePathname = pagesRouter ? pagesRouter.pathname : null;
  const pageAsPath = pagesRouter ? pagesRouter.asPath : null;
  const pageQuery = pagesRouter ? pagesRouter.query : null;
  // Return the relevant values for both routers
  return {
    router: isAppRouter ? getUseAppRouter : pagesRouter,
    pathname: isAppRouter ? pathname : pagePathname,
    asPath: isAppRouter ? asPath : pageAsPath,
    query: isAppRouter ? query : pageQuery,
  };
}
*/

export { useCustomRouter };
