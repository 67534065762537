import createCache, { EmotionCache } from "@emotion/cache";

/**
 * Creates an Emotion cache with a specified key for caching CSS styles.
 *
 * @returns {EmotionCache} An Emotion cache instance with a specified key.
 */
export const createEmotionCache = (): EmotionCache => {
  return createCache({ key: "css" });
};

export default createEmotionCache;