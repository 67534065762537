//
// server-side.ts
//

import {
  BackendApiResponse,
  BackendErrorResponse,
  BackendSuccessResponse,
} from "@data-types/backend-response-types";
import {
  HttpStatusCode,
  ServerErrorName,
} from "@data-types/server-error-types";
import { logThis } from "@lib/iso-utils";
import { NextApiRequest, NextApiResponse } from "next";
import { redirect } from "next/navigation";
import { NextResponse } from "next/server";

/**
 * Represents a custom server error.
 *
 * This class extends the built-in `Error` object to include additional
 * metadata such as the error name and HTTP status code.
 */
export class ServerError extends Error {
  name: ServerErrorName; // The name of the error, e.g., "ValidationError"
  statusCode: HttpStatusCode; // The HTTP status code, e.g., 400 or 404

  /**
   * Creates a new `ServerError` instance.
   *
   * @param name - The name of the error, corresponding to a `ServerErrorName`.
   * @param message - A descriptive message for the error.
   * @param statusCode - The HTTP status code for the error, corresponding to a `HttpStatusCode`.
   */
  constructor(
    name: ServerErrorName,
    message: string,
    statusCode: HttpStatusCode
  ) {
    super(message); // Call the parent Error constructor
    this.name = name;
    this.statusCode = statusCode;

    // Maintain proper stack trace (only in V8 environments like Node.js)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ServerError);
    }
  }
}

/**
 * Extracts the session token from cookies.
 *
 * @param {NextApiRequest} req - The incoming request object.
 * @param {NextApiResponse} res - The outgoing response object.
 * @returns {Promise<string | undefined>} The session token as a string if it exists, otherwise `undefined`.
 */
export async function getSessionFromCookie(
  req: NextApiRequest,
  res: NextApiResponse
): Promise<string | undefined> {
  // Retrieve the session token from cookies using the environment variable as the cookie name
  const session = req.cookies?.[process.env.SESSION_COOKIE as string];
  return session;
}

/**
 * Fetches data from the dashboard API with authentication and error handling.
 *
 * @param {NextApiRequest} req - The incoming request object.
 * @param {NextApiResponse} res - The outgoing response object.
 * @param {string} url - The endpoint to which the request will be sent.
 * @param {string} method - The HTTP method to use for the request.
 * @param {object | undefined} body - The request payload, if any.
 */
export async function dashboardApiFetcher(
  req: NextApiRequest,
  res: NextApiResponse,
  url: string,
  method: string,
  body?: object
): Promise<void> {
  // Set a timeout to trigger an error before the backend times out
  let reachTimeout = false;
  const id = setTimeout(
    () => {
      reachTimeout = true;
      res.status(530).json({
        name: "Timeout error",
        endpoint: url,
        message: "Server timed out handling your request.",
      });
    },
    parseInt(process.env.API_TIMEOUT || "10000")
  ); // Default to 10s if API_TIMEOUT is not set

  try {
    // Extract the session token from an HTTP-only cookie
    const session = await getSessionFromCookie(req, res);
    if (session) {
      const headers = new Headers();
      headers.append("Authorization", `Bearer ${session}`);
      const startExecutionTime = performance.now();
      let response;

      // Determine whether to include the body in the request
      if (!body) {
        response = await fetch(`${process.env.DASHBOARD_API_URL}${url}`, {
          method,
          headers,
        });
      } else {
        headers.append("Content-Type", "application/json");
        response = await fetch(`${process.env.DASHBOARD_API_URL}${url}`, {
          method,
          body: JSON.stringify(body),
          headers,
        });
      }

      const endExecutionTime = performance.now();
      const executionTime = endExecutionTime - startExecutionTime;

      // Check if the timeout was reached
      if (!reachTimeout) {
        clearTimeout(id);

        const data = await response.json();
        data.executionTime = executionTime;

        if (data.status === 200) {
          res.status(200).json(data);
        } else {
          // Handle backend error messages
          logThis(
            `Error message from backend. Error status ${data.status}. Error message: ${data.message}`
          );

          if (data.status === 401) {
            // Expire the session cookie and redirect to sign-in
            res.setHeader("Set-Cookie", [
              `${process.env.SESSION_COOKIE}=; MaxAge=0; Path=/; Secure=true; Expires=Thu, 01 Jan 1970 00:00:00 GMT; HttpOnly; SameSite=Lax`,
            ]);
            res.redirect(307, "/auth/sign-in");
          } else {
            res.status(data.status).json({
              name: "Backend error message",
              endpoint: url,
              message: data.message,
            });
          }
        }
      }
    } else {
      // Redirect to sign-in if no session token is found
      if (!reachTimeout) {
        clearTimeout(id);
        res.redirect(307, "/auth/sign-in");
      }
    }
  } catch (error: any) {
    console.error(error);
    clearTimeout(id);

    // Log unexpected errors not handled by the backend
    logThis(
      `Error not caught from backend. Error name: ${error.name} | Error message: ${error.message}`
    );

    res.status(533).json({
      name: error.name,
      endpoint: url,
      message: JSON.stringify(error, Object.getOwnPropertyNames(error)),
    });
  }
}

/**
 * Fetches data from the Kubernetes dashboard API with authentication and error handling.
 *
 * @param {NextApiRequest} req - The incoming request object.
 * @param {NextApiResponse} res - The outgoing response object.
 * @param {string} url - The endpoint to which the request will be sent.
 * @param {string} method - The HTTP method to use for the request.
 * @param {object | undefined} body - The request payload, if any.
 */
export async function dashboardApiFetcher_k8s(
  req: NextApiRequest,
  res: NextApiResponse,
  url: string,
  method: string,
  body?: object
): Promise<void> {
  // Timeout handler to trigger an error if the backend takes too long
  let reachTimeout = false;
  const id = setTimeout(
    () => {
      reachTimeout = true;
      res.status(530).json({
        name: "Timeout error",
        endpoint: url,
        message: "Server timed out handling your request.",
      });
    },
    parseInt(process.env.API_TIMEOUT || "10000")
  ); // Defaults to 10 seconds if not set

  try {
    // Retrieve the session token from an HTTP-only cookie
    const session = await getSessionFromCookie(req, res);
    if (session) {
      const headers = new Headers();
      const base64Credentials = btoa(
        `${process.env.K8S_LOGS_USER}:${process.env.K8S_LOGS_PSW}`
      );
      headers.append("Authorization", `Basic ${base64Credentials}`);
      headers.append("x-scope-orgid", "sqlitecloud");

      const urlTest = new URL(
        "https://loki.aws-eu1.sqlite.tech/loki/api/v1/query"
      );

      // Set up the query parameters
      const params = { query: '{namespace="sqlitecloud"}', time: "1725983481" };

      const startExecutionTime = performance.now();
      let response;

      // Send request without body if body is undefined
      if (!body) {
        response = await fetch(`${urlTest}`, {
          method,
          headers,
        });
      } else {
        headers.append("Content-Type", "application/json");
        response = await fetch(`${urlTest}`, {
          method,
          body: JSON.stringify(body),
          headers,
        });
      }

      const endExecutionTime = performance.now();
      const executionTime = endExecutionTime - startExecutionTime;

      // Handle response only if timeout was not reached
      if (!reachTimeout) {
        clearTimeout(id);

        const data = await response.json();
        data.executionTime = executionTime;

        if (data.status === 200) {
          res.status(200).json(data);
        } else {
          // Log backend error messages for further analysis
          logThis(
            `Error message from backend. Error status ${data.status}. Error message: ${data.message}`
          );

          if (data.status === 401) {
            // Expire the session cookie to remove it
            res.setHeader("Set-Cookie", [
              `${process.env.SESSION_COOKIE}=; MaxAge=0; Path=/; Secure=true; Expires=Thu, 01 Jan 1970 00:00:00 GMT; HttpOnly; SameSite=Lax`,
            ]);
            // Redirect user to the sign-in page
            res.redirect(307, "/auth/sign-in");
          } else {
            res.status(data.status).json({
              name: "Backend error message",
              endpoint: url,
              message: data.message,
            });
          }
        }
      }
    } else {
      // Redirect to sign-in if no session token is present
      if (!reachTimeout) {
        clearTimeout(id);
        res.redirect(307, "/auth/sign-in");
      }
    }
  } catch (error: any) {
    console.error(error);
    clearTimeout(id);

    // Log uncaught errors from the backend
    logThis(
      `Error not caught from backend. Error name: ${error.name} | Error message: ${error.message}`
    );

    res.status(533).json({
      name: error.name,
      endpoint: url,
      message: JSON.stringify(error, Object.getOwnPropertyNames(error)),
    });
  }
}

/**
 * Fetches data from the app router backend with the provided session and API details.
 *
 * @template T - The type of the response data expected from the backend.
 * @param session - The session token for authentication.
 * @param url - The API endpoint to fetch data from.
 * @param method - The HTTP method (e.g., "GET", "POST").
 * @param body - Optional request body for methods like POST or PUT.
 * @returns A promise resolving to the backend response.
 * @throws Throws an error if the request fails or a timeout occurs.
 */
export async function appRouterApiFetcher<T>(
  session: string,
  url: string,
  method: string,
  body?: object
): Promise<BackendApiResponse<T>> {
  // Set a timeout to trigger an error before the backend times out
  let reachTimeout = false;
  const id = setTimeout(
    () => {
      reachTimeout = true;
      throwServerError(
        "Timeout",
        `Server timedout calling endpoint ${url}`,
        533
      );
    },
    parseInt(process.env.API_TIMEOUT || "10000")
  ); // Default to 10s if API_TIMEOUT is not set

  // Ensure the session token is provided
  if (!session) {
    clearTimeout(id);
    // Redirect to the sign-in page if the session is missing
    redirect("/auth/sign-in");
  }

  const headers = new Headers();
  headers.append("Authorization", `Bearer ${session}`);

  if (body) {
    headers.append("Content-Type", "application/json");
  }

  const startExecutionTime = performance.now();

  const response = await fetch(`${process.env.DASHBOARD_API_URL}${url}`, {
    method,
    headers,
    body: body ? JSON.stringify(body) : undefined,
    cache: "no-cache",
  });
  const endExecutionTime = performance.now();

  // Check if the timeout was reached
  if (reachTimeout) {
    clearTimeout(id);
    throwServerError("Timeout", `Server timedout calling endpoint ${url}`, 533);
  }

  clearTimeout(id);

  // Parse the response data
  const data = await response.json();
  data.executionTime = endExecutionTime - startExecutionTime;
  if (response.ok) {
    // Return the successful response
    return data as BackendSuccessResponse<T>;
  } else {
    // Handle backend error responses
    if (response.status === 401) {
      throw new Error("Not Authorized");
    }

    throwServerError(
      "Backend error message",
      `message: ${data.message} - backend endpoint: ${url}` ||
        "An error occurred",
      response.status as HttpStatusCode
    );
  }
}

/**
 * Retrieves the value of a required URL parameter from a request object.
 * Throws an error if the specified parameter is not present.
 *
 * @param {Request} request - The HTTP request object containing the URL.
 * @param {string} parameter - The name of the required URL parameter.
 * @returns {string} - The value of the required URL parameter.
 * @throws {string} - An error message if the parameter is missing.
 */
export function getRequiredUrlParameter(
  request: Request,
  parameter: string
): string {
  const { searchParams } = new URL(request.url);
  const value = searchParams.get(parameter);
  if (!value) {
    throw `The ?${parameter}=xxx parameter is required when calling this API`;
  }
  return value;
}

/**
 * Creates a standardized response for a 400 Bad Request error.
 *
 * @param {string} [title] - Optional title to provide additional context for the error.
 * @returns {NextResponse} - A JSON response with the error details and a 400 status code.
 */
export function errorBadRequest(title?: string): NextResponse {
  return NextResponse.json(
    {
      error: {
        status: "400",
        title: title ? `Bad Request: ${title}` : "Bad request",
      },
    },
    { status: 400 }
  );
}

/**
 * Sends a successful API response.
 *
 * This utility function generates a standardized response format for use in Next.js API routes.
 * It includes essential details such as a success message, HTTP status code, execution time,
 * and the response data. It helps ensure consistent response structures across the API.
 *
 * @template T - The type of the `value` field in the response.
 * @param data - The data to include in the success response.
 * @param message - A descriptive success message (default: "OK").
 * @param status - The HTTP status code for the response (default: 200).
 * @param executionTime - The time taken to process the request in milliseconds (default: 0).
 * @returns A `NextResponse` containing the success response details in JSON format.
 */
export function sendSuccess<T>(
  data: T,
  message: string = "OK",
  status: number = 200,
  executionTime: number = 0
): NextResponse {
  const response: BackendSuccessResponse<T> = {
    message,
    status,
    executionTime,
    value: data,
  };
  return NextResponse.json(response, { status: status });
}

/**
 * Throws a custom `ServerError` to standardize error handling.
 *
 * This utility function simplifies the process of creating and throwing errors
 * in server-side logic or API routes. The `ServerError` includes metadata such
 * as a name, message, and status code, ensuring that errors are handled consistently.
 *
 * @param name - A short, descriptive name of the error (e.g., "ValidationError", "NotFoundError").
 * @param message - A detailed error message providing context about the issue.
 * @param statusCode - The HTTP status code associated with the error (default: 400).
 * @throws A `ServerError` instance with the provided details, ensuring it can be caught and processed appropriately.
 */
export function throwServerError(
  name: ServerErrorName,
  message: string,
  statusCode: HttpStatusCode = 400
): never {
  throw new ServerError(name, message, statusCode);
}

/**
 * Sends an error response for API routes.
 *
 * This function generates a standardized error response format for Next.js API routes.
 * It includes metadata such as the error name, the originating endpoint, a descriptive
 * error message, and the HTTP status code. This utility is ideal for consistently
 * handling errors across different parts of the application.
 *
 * @param name - A short identifier for the error type (e.g., "NotFoundError").
 * @param endpoint - The API endpoint where the error occurred.
 * @param message - A descriptive error message explaining the cause of the error.
 * @param status - The HTTP status code to send with the response (default: 400).
 * @returns A `NextResponse` containing the error details in JSON format with the specified HTTP status code.
 */
export function sendError(
  name: ServerErrorName,
  endpoint: string,
  message: string,
  status: HttpStatusCode = 400
): NextResponse {
  const response: BackendErrorResponse = {
    name,
    endpoint,
    message,
  };
  return NextResponse.json(response, { status: status });
}

/**
 * Handles errors in API routes, ensuring consistent logging and response format.
 *
 * This utility function processes both `ServerError` and generic errors,
 * returning appropriate error responses for Next.js API routes.
 *
 * @param error - The caught error from the try-catch block.
 * @param requestUrl - The URL of the request where the error occurred.
 * @param defaultErrorMessage - A default error message to use for generic errors.
 * @returns A `NextResponse` with the error details.
 */
export function handleApiError(
  error: any,
  requestUrl: string,
  defaultErrorMessage: string
): NextResponse {
  // Log the error details
  console.error(`Error occurred at ${requestUrl}: ${error.message}`, error);

  if (error instanceof ServerError) {
    // Handle known ServerError instances
    return sendError(error.name, requestUrl, error.message, error.statusCode);
  } else {
    // Handle unexpected errors
    return sendError(
      "InternalServerError",
      requestUrl,
      `${defaultErrorMessage}: ${error.message}`,
      500
    );
  }
}
