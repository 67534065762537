//
// convertDatabaseStatus.ts
//

import { DatabaseStatus } from "@data-types/databases-types";

/**
 * Converts a database status number to an object containing a tooltip message and a color based on the theme.
 *
 * @param {DatabaseStatus} status - The status code of the database.
 * @param {any} theme - The theme object containing color palette information.
 *
 * @returns {{
 *   tooltip: string;
 *   color: string;
 *   value: number;
 * }} An object representing the tooltip message, color, and status value.
 */
export function convertDatabaseStatus(
  status: DatabaseStatus,
  theme: any
): { tooltip: string; color: string; value?: number } {
  let databaseStatus: { tooltip: string; color: string; value?: number };

  switch (status) {
    case 0:
      databaseStatus = {
        tooltip:
          "File is not a valid SQLite database. If database is encrypted, provide an encryption by using the action menu.",
        color: theme.palette.neutral.lightGrey,
      };
      break;
    case 1:
      databaseStatus = {
        tooltip: "Database works as expected.",
        color: theme.palette.success.main,
      };
      break;
    case 2:
      databaseStatus = {
        tooltip: "Database is disabled.",
        color: theme.palette.neutral.lightGrey,
      };
      break;
    case 3:
      databaseStatus = {
        tooltip: "Database in maintenance mode.",
        color: theme.palette.warning.main,
      };
      break;
    case 4:
      databaseStatus = {
        tooltip:
          "Database is in error state. If database is encrypted, provide an encryption by using the action menu.",
        color: theme.palette.error.main,
      };
      break;
    default:
      databaseStatus = {
        tooltip: "UNKNOWN",
        color: theme.palette.neutral.lightGrey,
      };
      break;
  }

  // Add the status value to the returned object
  databaseStatus.value = status;
  return databaseStatus;
}
